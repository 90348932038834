import { Link, graphql } from 'gatsby'

import { MicroCmsBlog, SiteData } from '../../types'
import { Bio } from '../components/Bio'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

interface BlogIndexProps {
  data: {
    site: SiteData
    allMicrocmsBlog: {
      nodes: MicroCmsBlog[]
    }
  }
  location: Location
}

const BlogIndex = ({ data, location }: BlogIndexProps) => {
  const siteTitle: string = data.site.siteMetadata?.title || 'Title'
  const posts: MicroCmsBlog[] = data.allMicrocmsBlog.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to &quot;content/blog&quot; (or the
          directory you specified for the &quot;gatsby-source-filesystem&quot; plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <Bio />
      <ol style={{ listStyle: 'none' }}>
        {posts.map(post => {
          const title = post.title
          const date = post.createdAt.split('T')[0]

          return (
            <li key={post.id}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={`blog/${post.id}`} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  {/* <small>{date}</small> */}
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.description
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMicrocmsBlog(sort: {fields: createdAt, order: DESC}) {
      nodes {
        id
        title
        createdAt
        content
        description
      }
    }
  }
`
